import PropTypes from "prop-types"
import React from "react"

import { useTranslation } from "../../../i18n/translate"
import { MarketModel } from "../../../model/marketModel"
import GenericPreview from "../generic-preview"

const MarketPreview = ({ market }) => {
  const { t } = useTranslation()
  return (
    <GenericPreview
      image={market.cover_image?.childImageSharp}
      imageAltTranslationKey={market.name.key}
      titleTranslationKey={market.name.key}
      linkToProductsPageTextTranslationKey={"PRODUCTS-VIEW_ALL_PRODUCTS"}
      linkToDetailPagePath={market.getDetailPageUrl()}
      linkToProductsPagePath={market.getProductPageUrl()}
      descriptionTranslationKey={market.description?.key}
      moreAboutThisTranslatedString={t("MARKETS-MORE_ABOUT_THIS_MARKET")}
    />
  )
}

MarketPreview.propTypes = {
  market: PropTypes.instanceOf(MarketModel),
}

export default MarketPreview
